/* eslint-disable max-len */
import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: 'Fullstack Developer',
  introTitle: 'Fullstack Developer (m/w/d) full-time',
  introInfo: '<p>freshcells systems engineering GmbH is a Düsseldorf-based software agency with a dynamic team of over 40 employees.</p><p>We do not have a hire-and-fire mentality; we prefer to kick back with cold drinks after work.</p><p>Among other vacancies, we are looking for a Fullstack Software Developer (full-time software development) to support our team in Düsseldorf.</p><p>freshcells is always on the lookout for the latest software trends and principles. To run with the most modern software stacks is part of our DNA. Docker, Kubernetes, React, NodeJS … freshcells has been an early adopter and contributor in all fields. If you feel at home in such an environment and enjoy working with talented co-workers from all over the world who challenge you and like to be challenged by you, you are the right fit for us.</p>',
  responsibilitiesTitle: 'Your Responsibilities',
  responsibility1: 'Development of modern backend applications based on Node.Js',
  responsibility2: 'Development of modern frontend applications based on React JS',
  responsibility3: 'Development of APIs based on GraphQL',
  responsibility4: 'Design and implementation of new features for sophisticated software solutions in cooperation with project management, design, and development departments',
  responsibility5: 'Writing and maintaining our automated test suites',
  responsibility6: 'Participation in continuous improvement processes',
  qualificationsTitle: 'Your Qualifications',
  qualification1: 'Very good knowledge of Node.js',
  qualification2: 'Strong knowledge of ES 6 JavaScript as well as extensive knowledge of frameworks and libraries such as reactJS, vue.js and/or Angular',
  qualification3: 'Experience of PHP, ideally with frameworks such as Symfony, Laravel or Yii',
  qualification4: 'Sound experience with relational or non-relational databases',
  qualification5: 'Good knowledge of data structure design',
  qualification6: 'Competences in consuming and creating web services based on ReST and especially GraphQL',
  qualification7: 'Know-how in performance optimization',
  qualification8: 'Basic knowledge of HTML and CSS is helpful',
  qualification9: 'Experience with agile development in a team',
  qualification10: 'Hands-on experience with automated software testing',
  qualification11: 'Basic knowledge in API security',
  qualification12: 'Fluent in English',
  NTHTitle: 'Nice to have',
  nicetohave1: 'Experience with containerization technologies such as Docker etc.',
  nicetohave2: 'Experience with container orchestration tools like, i.a., Kubernetes, OpenShift, or Docker Swarm',
  nicetohave3: 'Knowledge of designing and adapting software to be twelve factor applications',
  nicetohave4: 'Knowledge of continuous integration via tools like Jenkins',
  nicetohave5: 'Experience with Next.js',
  nicetohave6: 'Experience with TypeScript'
})
